import clsx from 'clsx'
import Image from 'next/image'
import { useEffect, useState, type Dispatch, type FunctionComponent, type SetStateAction } from 'react'
import type { AsideMediaContentResult } from '../data/AsideMediaContentFragment'
import type { VideoItemResult } from '../data/VideoItemFragment'
import { contemberLinkToHrefTargetRel } from '../utilities/contemberLinkToHref'
import styles from './AsideMediaContent.module.sass'
import { Button } from './Button'
import { Container } from './Container'

export type AsideMediaContentProps = AsideMediaContentResult

export const AsideMediaContent: FunctionComponent<AsideMediaContentProps> = ({
	mediaType,
	contentAlignment,
	title,
	text,
	buttonLink,
	image,
	videoList,
}) => {
	return (
		<div className={styles.wrapper}>
			<Container>
				<div className={clsx(styles.main, styles[`is_${contentAlignment}`])}>
					<div className={styles.content}>
						{title && <h1 className={styles.title}>{title}</h1>}
						{text && <p className={styles.text}>{text}</p>}
						{buttonLink && (
							<div className={styles.buttonLink}>
								<Button type="link" {...contemberLinkToHrefTargetRel(buttonLink)}>
									{buttonLink.title}
								</Button>
							</div>
						)}
					</div>
					<div className={styles.media}>
						{mediaType === 'videos' && videoList ? (
							<VideoList items={videoList.items} />
						) : mediaType === 'image' && image ? (
							<div
								className={styles.image}
								style={{
									'--AsideMediaContent-image-width': `${image.width}`,
									'--AsideMediaContent-image-height': `${image.height}`,
								}}
							>
								<Image
									className={styles.imageIn}
									src={image.url}
									alt={image.alt ?? ''}
									fill
									sizes="(min-width: 600px) 560px, (min-width: 768px) 370px, (min-width: 850px) 500px, (min-width: 1020px) 560px, 100vw"
								/>
							</div>
						) : null}
					</div>
				</div>
			</Container>
		</div>
	)
}

type VideoListProps = Pick<NonNullable<AsideMediaContentResult['videoList']>, 'items'>

export const VideoList: FunctionComponent<VideoListProps> = ({ items }) => {
	const [activeIndex, setActiveIndex] = useState<number>(0)
	return (
		<div className={styles.videos}>
			<div className={styles.videoList}>
				{items.map(
					(videoItem, index) =>
						videoItem.video && (
							<VideoItem
								key={videoItem.id}
								{...videoItem}
								index={index}
								activeIndex={activeIndex}
								setActiveIndex={setActiveIndex}
								videoListCount={items.length}
							/>
						),
				)}
			</div>
		</div>
	)
}

type VideoItemProps = VideoItemResult & {
	index: number
	setActiveIndex: Dispatch<SetStateAction<number>>
	activeIndex: number
	videoListCount: number
}

const VideoItem: FunctionComponent<VideoItemProps> = ({
	video,
	index,
	setActiveIndex,
	activeIndex,
	videoListCount,
}) => {
	const [videoRef, setVideoRef] = useState<HTMLVideoElement | null>(null)

	useEffect(() => {
		const videoCurrentTime = () => {
			videoRef?.addEventListener('ended', () => {
				setActiveIndex(activeIndex <= 0 ? videoListCount - 1 : activeIndex - 1)
			})
		}
		if (videoRef) {
			if (activeIndex === index) {
				videoRef.play()
				videoCurrentTime()
			} else {
				!videoRef.paused && videoRef.pause()
			}
		}

		return () => {
			videoRef?.removeEventListener('ended', videoCurrentTime)
		}
	}, [activeIndex, index, setActiveIndex, videoListCount, videoRef])

	if (!video) {
		return null
	}

	return (
		<div
			key={video.id}
			className={clsx(styles.videoItem, activeIndex === index && styles.is_active)}
			style={{
				'--AsideMediaContent-item-index': activeIndex - index,
				'--AsideMediaContent-item-count': videoListCount,
			}}
			onClick={() => activeIndex !== index && setActiveIndex(index)}
		>
			<video ref={setVideoRef} muted playsInline className={styles.videoIn} poster={video.poster?.url}>
				<source src={video.src} type={video.type} />
			</video>
		</div>
	)
}
