import type { GetStaticPaths, GetStaticProps, InferGetStaticPropsType } from 'next'
import { GenericPage } from '../components/GenericPage'
import { HomePage } from '../components/HomePage'
import { Layout } from '../components/Layout'
import { Seo } from '../components/Seo'
import { ContactSubmissionContextProvider } from '../components/contexts/ContactSubmissionProvider'
import { SwitcherLocalesContextProvider } from '../components/contexts/SwitcherLocalesContext'
import { TranslationsContextProvider } from '../components/contexts/TranslationsContextProvider'
import { ContactSubmissionContactPersonFragment } from '../data/ContactSumissionContactPersonFragment'
import { FooterFragment } from '../data/FooterFragment'
import { GeneralFragment } from '../data/GeneralFragment'
import { GenericPageLocaleFragment } from '../data/GenericPageLocaleFragment'
import { HeaderFragment } from '../data/HeaderFragment'
import { HomepageLocaleFragment } from '../data/HomepageLocaleFragment'
import { InternalLinkFragment } from '../data/InternalLinkFragment'
import { LinkFragment } from '../data/LinkFragment'
import { TranslationDomainWithCatalougesByIdentifierFragment } from '../data/TranslationDomainWithCatalougesByIdentifierFragment'
import { contember } from '../utilities/contember'
import { contemberLinkToHref } from '../utilities/contemberLinkToHref'
import { getLinkableUrlFromContext } from '../utilities/getLinkableUrlFromContext'
import { isDefined } from '../utilities/isDefined'

export type PageProps = InferGetStaticPropsType<typeof getStaticProps>

export default function ({
	header,
	page,
	footer,
	currency,
	pageLocales,
	currentLocaleCode,
	translations,
	seo,
	contactSubbmisionData,
}: PageProps) {
	return (
		<>
			<Seo {...seo} />
			<TranslationsContextProvider data={translations}>
				<SwitcherLocalesContextProvider pageLocales={pageLocales} currentLocaleCode={currentLocaleCode}>
					<ContactSubmissionContextProvider data={contactSubbmisionData}>
						<Layout header={header} footer={footer}>
							{page.homePage ? (
								<HomePage {...page.homePage} currency={currency} />
							) : page.genericPage ? (
								<GenericPage {...page.genericPage} />
							) : null}
						</Layout>
					</ContactSubmissionContextProvider>
				</SwitcherLocalesContextProvider>
			</TranslationsContextProvider>
		</>
	)
}

export const getStaticPaths = (async (context) => {
	const { listLinkable } = await contember.query({
		listLinkable: [
			{
				filter: {
					redirect: { id: { isNull: true } },
				},
			},
			{
				id: true,
				url: true,
			},
		],
	})

	return {
		paths: listLinkable.map((link) => {
			const path = link.url.split('/').filter((part) => part !== '')

			let locale: string | undefined

			if (context.locales?.includes(path[0])) {
				locale = path.shift()
			}

			return {
				locale,
				params: {
					path,
				},
			}
		}),
		fallback: 'blocking',
	}
}) satisfies GetStaticPaths

export const getStaticProps = (async (context) => {
	const url = getLinkableUrlFromContext(context)
	const { locale } = context

	if (!locale) {
		throw new Error('Locale not defined.')
	}

	const data = await contember.query({
		getGeneral: [
			{
				by: {
					unique: 'One',
				},
			},
			GeneralFragment(locale),
		],
		getHeader: [
			{
				by: {
					unique: 'One',
				},
			},
			HeaderFragment(locale),
		],
		getFooter: [
			{
				by: {
					unique: 'One',
				},
			},
			FooterFragment(locale),
		],
		__alias: {
			getLinkableWithTrailingSlash: {
				getLinkable: [
					{
						by: { url: `${url}/` },
					},
					{
						url: true,
						homePage: [{}, HomepageLocaleFragment()],
						genericPage: [{}, GenericPageLocaleFragment()],
						redirect: [
							{},
							{
								id: true,
								target: [{}, LinkFragment()],
							},
						],
					},
				],
			},
		},
		getLinkable: [
			{
				by: { url },
			},
			{
				url: true,
				homePage: [{}, HomepageLocaleFragment()],
				genericPage: [{}, GenericPageLocaleFragment()],
				redirect: [
					{},
					{
						id: true,
						target: [{}, LinkFragment()],
					},
				],
			},
		],
		listHomepageLocale: [
			{},
			{
				id: true,
				locale: [
					{},
					{
						label: true,
						code: true,
					},
				],
				link: [{}, InternalLinkFragment()],
			},
		],
		getTranslationDomain: [
			{
				by: {
					identifier: 'app',
				},
			},
			TranslationDomainWithCatalougesByIdentifierFragment(locale),
		],
		listContactSubmissionContactPerson: [{}, ContactSubmissionContactPersonFragment(locale)],
	})

	const localeList = (() => {
		const locales = data.listHomepageLocale
			.map(
				(homePage) =>
					homePage.locale && {
						label: homePage.locale.label,
						code: homePage.locale.code,
						url: homePage.link?.url,
					},
			)
			.filter(isDefined)

		return locales
	})()

	const getLinkable = data.getLinkable ?? data.getLinkableWithTrailingSlash

	const redirectUrl = (() => {
		const target = getLinkable?.redirect?.target
		return target ? contemberLinkToHref(target) : null
	})()

	if (redirectUrl) {
		return {
			redirect: {
				permanent: false,
				destination: redirectUrl,
			},
		}
	}

	const canonicalUrl = (() => {
		const url = getLinkable?.url
		if (!url) {
			return null
		}
		return (process.env.NEXT_PUBLIC_WEB_URL ?? '') + url
	})()

	const homePage = getLinkable?.homePage

	const genericPage = getLinkable?.genericPage

	const page = {
		homePage,
		genericPage,
	}

	const currentPage = Object.values(page).find((page) => page !== null)

	if (!currentPage) {
		return {
			notFound: true,
			revalidate: 60,
		}
	}

	const header = await (async () => {
		const initial = data.getHeader

		if (!initial) {
			return null
		}

		return initial
	})()

	const footer = await (async () => {
		const initial = data.getFooter

		if (!data.getFooter) {
			return null
		}

		return { ...initial, contactInfoCard: data.getGeneral?.localesByLocale?.contactInfo ?? null, localeList }
	})()

	const currency = data.getGeneral?.localesByLocale?.currency ?? null
	const translations = data.getTranslationDomain
	const contactSubbmisionData = {
		contactPersons: data.listContactSubmissionContactPerson,
	}

	return {
		props: {
			general: data.getGeneral,
			header,
			footer,
			page,
			localeList,
			currency,
			translations,
			pageLocales: currentPage.root?.locales ?? [],
			currentLocaleCode: context.locale ?? context.defaultLocale,
			contactSubbmisionData,
			seo: {
				canonicalUrl,
				seo: {
					...(data.getGeneral?.localesByLocale?.seo ?? {}),
					...Object.fromEntries(Object.entries(currentPage.seo ?? {}).filter(([_, value]) => Boolean(value))),
				},
			},
		},
		revalidate: 60,
	}
}) satisfies GetStaticProps
