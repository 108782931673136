import clsx from 'clsx'
import { Collapsible } from 'collapsible-react-component'
import { useState, type FunctionComponent } from 'react'
import type { FeatureResult } from '../data/FeatureFragment'
import styles from './FeatureAccordion.module.sass'
import { Icon } from './Icon'

export type FeatureAccordionProps = Pick<
	FeatureResult,
	'label' | 'text' | 'textColorType' | 'verticalAlignmentType' | 'horizontalAlignmentType'
>

export const FeatureAccordion: FunctionComponent<FeatureAccordionProps> = ({
	label,
	text,
	textColorType = 'light',
	horizontalAlignmentType = 'center',
	verticalAlignmentType = 'top',
}) => {
	const [isOpen, setIsOpen] = useState(false)

	return (
		<div
			className={clsx(
				styles.wrapper,
				styles[`is_textColor_${textColorType}`],
				styles[`is_horizontal_${horizontalAlignmentType}`],
				styles[`is_vertical_${verticalAlignmentType}`],
				isOpen && styles.is_open,
			)}
		>
			<button type="button" onClick={() => setIsOpen((value) => !value)} className={styles.header}>
				<div className={styles.label}>{label}</div>{' '}
				<div className={styles.icon}>
					<div className={styles.iconMinus}>
						<Icon name="minusCirle" />
					</div>
					<div className={styles.iconPlus}>
						<Icon name="plusCirle" />
					</div>
				</div>
				<div className={styles.gradient}>
					<div className={styles.gradientIn} />
				</div>
			</button>
			<div className={styles.body}>
				<Collapsible open={isOpen} revealType={verticalAlignmentType === 'bottom' ? 'topFirst' : 'bottomFirst'}>
					<div className={styles.bodyText}>{text}</div>
				</Collapsible>
			</div>
		</div>
	)
}
