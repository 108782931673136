import React, { useContext } from 'react'
import { v4 } from 'uuid'
import type { PageProps } from '../../pages/[[...path]]'

export type SwitcherLocalesProps = {
	pageLocales?: PageProps['pageLocales']
	currentLocaleCode?: PageProps['currentLocaleCode']
}

const defaultSwitcherLocalesValues: SwitcherLocalesProps = {
	currentLocaleCode: 'en',
	pageLocales: [
		{
			id: v4(),
			locale: {
				code: 'en',
				label: 'English',
			},
			link: {
				url: '#',
			},
		},
	],
}

export const SwitcherLocalesContext = React.createContext<SwitcherLocalesProps | undefined>(
	defaultSwitcherLocalesValues,
)

export const SwitcherLocalesContextProvider: React.FunctionComponent<
	SwitcherLocalesProps & { children: React.ReactNode }
> = ({ children, ...props }) => {
	return <SwitcherLocalesContext.Provider value={props}>{children}</SwitcherLocalesContext.Provider>
}

export const useLocales = () => {
	const getLocales = useContext(SwitcherLocalesContext)
	return getLocales
}
