import type { FunctionComponent, InputHTMLAttributes } from 'react'
import styles from './Checkbox.module.sass'
import { useTranslate } from './contexts/TranslationsContextProvider'

export type CheckboxProps = Pick<
	InputHTMLAttributes<HTMLInputElement>,
	'name' | 'required' | 'value' | 'onChange' | 'onFocus' | 'onBlur' | 'checked'
> & {
	label: string
}

export const Checkbox: FunctionComponent<CheckboxProps> = ({ label, ...props }) => {
	const translate = useTranslate()
	return (
		<label className={styles.wrapper}>
			<input className={styles.checkbox} type="checkbox" {...props} />
			<div className={styles.checkboxCustom} />
			<p className={styles.label}>
				<span
					dangerouslySetInnerHTML={{
						__html: label,
					}}
				/>
				{props.required && <span className={styles.required}> {translate('checkbox.required')}</span>}
			</p>
		</label>
	)
}
