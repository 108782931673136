import { useEffect, useRef, useState, type FunctionComponent } from 'react'
import { useIntersection } from 'react-use'
import { Container } from './Container'
import styles from './HeroShape.module.sass'

const easeInOutSine = (x: number): number => {
	return -(Math.cos(Math.PI * x) - 1) / 2
}

type HeroShapeProps = {
	displayWhen?: 'fullyInView' | 'isIntersecting'
	animationSpeed?: 'standard' | 'slow'
}

const standardProgressVelocity = 0.015
const slowProgressVelocity = 0.01

export const HeroShape: FunctionComponent<HeroShapeProps> = ({
	displayWhen = 'fullyInView',
	animationSpeed = 'standard',
}) => {
	const intersectionRef = useRef(null)

	const intersection = useIntersection(intersectionRef, {
		root: null,
		rootMargin: '-50px',
		threshold: 1,
	})

	const [run, setRun] = useState(false)

	const [progress, setProgress] = useState(0)
	useEffect(() => {
		if (!run) {
			return
		}
		let timer: ReturnType<typeof requestAnimationFrame>

		const loop = () => {
			const speed =
				animationSpeed === 'standard'
					? standardProgressVelocity
					: animationSpeed === 'slow'
					? slowProgressVelocity
					: (animationSpeed satisfies undefined)
			setProgress((progress) => Math.min(progress + speed, 1))
			timer = requestAnimationFrame(loop)
		}
		loop()
		return () => {
			cancelAnimationFrame(timer)
		}
	}, [animationSpeed, run])

	useEffect(() => {
		// if (intersection && intersection.intersectionRatio === 1) {
		if (intersection) {
			if (displayWhen === 'fullyInView' && intersection.intersectionRatio === 1) {
				setRun(true)
			} else if (displayWhen === 'isIntersecting' && intersection.isIntersecting) setRun(true)
		} else {
			setRun(false)
		}
	}, [displayWhen, intersection])

	const [isMobile, setIsMobile] = useState(false)

	useEffect(() => {
		const handleResize = () => {
			setIsMobile(window.innerWidth < 480)
		}
		handleResize()
		window.addEventListener('resize', handleResize)
		return () => window.removeEventListener('resize', handleResize)
	}, [])

	return (
		<div className={styles.wrapper} ref={intersectionRef}>
			<Container>
				<svg
					className={styles.shape}
					viewBox="0 0 357.18 223.23"
					width="357.18"
					height="223.23"
					version="1.1"
					xmlns="http://www.w3.org/2000/svg"
				>
					<mask id="myMask">
						<rect width="100%" height="100%" fill="#000000" />
						<path
							strokeDasharray={811}
							strokeDashoffset={isMobile ? 0 : 811 * (1 - easeInOutSine(progress))}
							fill="none"
							transform="translate(-3.4 -.32376)"
							d="m91.2 177.2c-31.5-33.8-62.7-101.6-68.3-142.8 0-16.2 13.3-17.1 20.5-10.8 7 6.4 222.5 172 236.8 177.6 14.2 5.6 27 1.5 19.5-14-7.5-15.3-51.8-98.1-57.8-108-9.4-15.4 0-27 17.6-15.4 6.2859 4.1195 14.59 9.6642 23.339 15.505 15.888 10.607 33.246 22.191 42.661 27.995 14.7 9 19.2-3.3 11.1-32"
							stroke="#FFFFFF"
							strokeLinecap="round"
							strokeLinejoin="round"
							strokeWidth="39"
						/>
					</mask>
					<image
						href="/shapes/splashed-swash-animation.svg"
						x="0"
						y="0"
						width="357.18"
						height="223.23"
						mask="url(#myMask)"
					/>
				</svg>
			</Container>
		</div>
	)
}
