import clsx from 'clsx'
import Image from 'next/image'
import { useEffect, useMemo, useState, type FunctionComponent } from 'react'
import type { ImageResult } from '../data/ImageFragment'
import type { LinkResult } from '../data/LinkFragment'
import type { LogoListResult } from '../data/LogoListFragment'
import styles from './LogoList.module.sass'

export type LogoListProps = LogoListResult

export const LogoList: FunctionComponent<LogoListProps> = ({ items }) => {
	const [listRef, setListRef] = useState<HTMLDivElement | null>(null)
	const [isScrollAnimated, setIsScrollAnimated] = useState(false)
	useEffect(() => {
		if (!window.matchMedia('(prefers-reduced-motion: reduce)').matches && listRef) {
			const items = Array.from(listRef.children)
			setIsScrollAnimated(true)
			items.forEach((item) => {
				const duplicatedItem = item.cloneNode(true)
				listRef.appendChild(duplicatedItem)
			})
		}
	}, [listRef])
	return (
		<div className={styles.wrapper}>
			<div className={clsx(styles.main, isScrollAnimated && styles.is_scrollAnimated)}>
				<div className={styles.list} ref={setListRef}>
					{items.map((item) => item.logo?.image && <Item key={item.id} image={item.logo.image} />)}
					{items.map((item) => item.logo?.image && <Item key={item.id} image={item.logo.image} />)}
				</div>
			</div>
		</div>
	)
}

type ItemProps = {
	image: ImageResult
	link?: LinkResult
	index?: number
}

const Item: FunctionComponent<ItemProps> = ({
	image: { url, width = 1, height = 1, alt = '' },
	//link,
	index,
}) => {
	const volume = useMemo(
		() => (width * height) / Math.pow(Math.max(width, ((height - width) / (height / width)) * 2), 2),
		[height, width],
	)
	const aspectRatio = useMemo(() => width / height, [height, width])

	return (
		<div
			className={clsx(styles.item, index === 5 && styles.is_test)}
			style={{
				['--LogoList-aspectRatio']: aspectRatio,
				['--LogoList-volume']: volume,
			}}
		>
			{/* @TODO: link */}
			<Image className={styles.image} src={url} width={width} height={height} alt={alt ?? ''} sizes="260px" />
		</div>
	)
}
