import clsx from 'clsx'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { useMemo, type FunctionComponent } from 'react'
import type { PageProps } from '../pages/[[...path]]'
import { Icon } from './Icon'
import styles from './LocaleSwitcher.module.sass'
import { useLocales } from './contexts/SwitcherLocalesContext'

export type LocaleSwitcherProps = NonNullable<Pick<PageProps, 'localeList'>> & {
	isIcon?: boolean
	isPadding?: boolean
}

export const LocaleSwitcher: FunctionComponent<LocaleSwitcherProps> = ({ isIcon = false, isPadding = false }) => {
	const { locale: currentLocale } = useRouter()
	const locales = useLocales()

	const localesWithoutCurrentLocale = useMemo(() => {
		return locales?.pageLocales?.filter((pageLocale) => pageLocale.locale?.code !== currentLocale)
	}, [currentLocale, locales?.pageLocales])

	if (!localesWithoutCurrentLocale || localesWithoutCurrentLocale?.length <= 0) {
		return null
	}

	return (
		<div className={styles.wrapper}>
			<div className={styles.button}>
				{/* There suppose to be just "english" and "čeština" locales, if other locaes are added, it must be changed */}
				{localesWithoutCurrentLocale.map(
					(pageLocale) =>
						pageLocale.link?.url && (
							<Link
								key={pageLocale.id}
								className={clsx(styles.link, isPadding && styles.is_padding)}
								href={pageLocale.link.url}
								locale={pageLocale.locale?.code}
							>
								{isIcon && (
									<div className={styles.logo}>
										<Icon name="globe" />
									</div>
								)}
								{pageLocale.locale?.label ?? pageLocale.locale?.code}
							</Link>
						),
				)}
			</div>
		</div>
	)
}
