import type { FunctionComponent } from 'react'
import type { PageProps } from '../pages/[[...path]]'
import { ContentRenderer } from './ContentRenderer'
import styles from './GenericPage.module.sass'

export type GenericPageProps = NonNullable<PageProps['page']['genericPage']>

export const GenericPage: FunctionComponent<GenericPageProps> = ({ content }) => {
	if (!content) {
		return null
	}
	return (
		<div className={styles.wrapper}>
			<ContentRenderer content={content} />
		</div>
	)
}
