import clsx from 'clsx'
import Link from 'next/link'
import type { FunctionComponent } from 'react'
import styles from './ContactLink.module.sass'
import { Icon } from './Icon'

export type ContactLinkProps = {
	type: 'email' | 'phone'
	link: string
	label: string
	buttonLabel: string
}

export const ContactLink: FunctionComponent<ContactLinkProps> = ({ type, link, label, buttonLabel }) => {
	return (
		<Link
			className={clsx(styles.wrapper, styles[`is_${type}`])}
			href={`${type === 'email' ? 'mailto:' : type === 'phone' ? 'tel:' : (type satisfies null)}${link}`}
		>
			<div className={styles.main}>
				<div className={styles.label}>{label}</div>
				<div className={styles.button}>
					<div className={styles.buttonLabel}>{buttonLabel}</div>
					<div className={styles.arrow}>
						<Icon name="arrow" />
					</div>
				</div>
			</div>
		</Link>
	)
}
