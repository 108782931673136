import Image from 'next/image'
import Link from 'next/link'
import type { FunctionComponent } from 'react'
import type { PageProps } from '../pages/[[...path]]'
import { contemberLinkToHrefTargetRel } from '../utilities/contemberLinkToHref'
import { BreakableContent } from './BreakableContent'
import { Container } from './Container'
import styles from './Footer.module.sass'
// import { Icon, iconNames } from './Icon'
import { LocaleSwitcher } from './LocaleSwitcher'
// import { useTranslate } from './contexts/TranslationsContextProvider'

export type FooterProps = NonNullable<PageProps['footer']>

export const Footer: FunctionComponent<FooterProps> = ({
	logo,
	localesByLocale,
	// contactInfoCard,
	localeList,
}) => {
	// const translation = useTranslate()
	// const contactButtonLink = localesByLocale?.contactButtonLink
	// const contactIcon = localesByLocale?.contactIcon
	// const contactText = localesByLocale?.contactText
	const copyright = localesByLocale?.copyright
	const linkList = localesByLocale?.linkList

	return (
		<div className={styles.wrapper}>
			<Container>
				<div className={styles.main}>
					<div className={styles.column}>
						<div className={styles.logo}>
							{logo && <Image src={logo.url} width={logo.width} height={logo.height} alt={logo.alt ?? ''} />}
							<div className={styles.locales}>
								<LocaleSwitcher localeList={localeList} isIcon />
							</div>
						</div>
						<div className={styles.copyright}>{copyright && <BreakableContent text={copyright} />}</div>
						{linkList && linkList.items.length > 0 && (
							<ul className={styles.linkList}>
								{linkList.items.map(
									(item) =>
										item.link && (
											<li key={item.id} className={styles.linkItem}>
												<Link className={styles.link} {...contemberLinkToHrefTargetRel(item.link)}>
													{item.link.title}
												</Link>
											</li>
										),
								)}
							</ul>
						)}
					</div>
					{/* <div className={styles.column}>
						{contactButtonLink && (
							<div className={styles.card}>
								<Link className={styles.contactLink} {...contemberLinkToHrefTargetRel(contactButtonLink)}>
									{contactText && (
										<div className={styles.contactContent}>
											<div className={styles.contactButton}>
												{contactIcon && (
													<Image
														src={contactIcon.url}
														width={contactIcon.width}
														height={contactIcon.height}
														alt={contactIcon.alt ?? ''}
													/>
												)}
												<ButtonWithArrow label={contactButtonLink.title ?? ''} />
											</div>
											<div className={styles.contactText}>{contactText}</div>
										</div>
									)}
								</Link>
							</div>
						)}
						{contactInfoCard && (
							<div className={styles.card}>
								<div className={styles.info}>
									{contactInfoCard.email && (
									@TODO: use ContactLink component
										<Link className={styles.infoRow} href={`mailto:${contactInfoCard.email}`}>
											<div className={styles.infoRowIn}>
												<div className={styles.infoContact}>{contactInfoCard.email}</div>
												<ButtonWithArrow label={translation('footer.email.label')} />
											</div>
										</Link>
									)}
									{contactInfoCard.phone && (
									@TODO: use ContactLink component
										<Link className={styles.infoRow} href={`tel:${contactInfoCard.phone}`}>
											<div className={styles.infoRowIn}>
												<div className={styles.infoContact}>{contactInfoCard.phone}</div>
												<ButtonWithArrow label={translation('footer.phone.label')} />
											</div>
										</Link>
									)}
									{contactInfoCard.socialLinks?.items && contactInfoCard.socialLinks.items.length > 0 && (
										<div className={styles.infoSocials}>
											{contactInfoCard.socialLinks.items.map((item) => {
												if (!item.socialLink) {
													return null
												}
												// In the line "//*" below the Typescript says that 'item.socialLink" is possibly "undefined"' 
												// even though there is a condition above. And AIs (ChatGPT/Perplexity) says that 
												// TS compiler is unable to guarantee that the "item.socialLink" is defined...

												//* const type = iconNames.find((iconName) => iconName === item.socialLink.type)
												const socialType = item.socialLink.type
												const type = iconNames.find((iconName) => iconName === socialType)
												return (
													<Link key={item.id} className={styles.infoSocialLink} href={item.socialLink.url}>
														{type ? (
															<div className={styles.infoIcon}>
																<Icon name={type} />
															</div>
														) : (
															item.socialLink.type
														)}
													</Link>
												)
											})}
										</div>
									)}
								</div>
							</div>
						)}
					</div> */}
				</div>
			</Container>
		</div>
	)
}

// const ButtonWithArrow: FunctionComponent<{ label: string }> = ({ label }) => {
// 	return (
// 		<div className={styles.buttonWithArrow}>
// 			<div className={styles.buttonWithArrowMain}>{label}</div>
// 			<div className={styles.arrow}>
// 				<Icon name="arrow" />
// 			</div>
// 		</div>
// 	)
// }
