import { RichTextRenderer } from '@contember/react-client'
import { PersonIcon, type PersonIconElement } from './PersonIcon'

type CustomElements = PersonIconElement

export const RichTextRendererWithElements: typeof RichTextRenderer = (props) => {
	return (
		<RichTextRenderer
			{...props}
			renderElement={(...args) => {
				const [params] = args
				const element = params.element as (typeof params)['element'] | CustomElements
				switch (element.type) {
					case 'personIcon': {
						const elementPersonIcon = element as PersonIconElement
						return <PersonIcon type={elementPersonIcon.suchThat.person} />
					}
				}
				const { renderElement } = props
				if (renderElement && typeof renderElement === 'function') {
					// eslint-disable-next-line @typescript-eslint/no-explicit-any
					return (renderElement as any)(...args)
				}
				return params.fallback
			}}
		/>
	)
}
