import clsx from 'clsx'
import Image from 'next/image'
import type { DistinctBaseButtonProps, SharedBaseButtonProps } from 'nextjs-button-base'
import { ButtonBase } from 'nextjs-button-base'
import { useMemo, type FunctionComponent } from 'react'
import { Ripple } from 'react-ripple-click'
import type { ImageResult } from '../data/ImageFragment'
import styles from './Button.module.sass'
import { Icon, type IconName } from './Icon'

export type ButtonProps = (
	| {
			variant?: 'primary' | 'form'
	  }
	| ButtonVariantSecondaryProps
	| ButtonVariantTertiaryProps
) & {
	size?: 'small' | 'large'
	isFullWidth?: boolean
} & Omit<SharedBaseButtonProps, 'className'> &
	DistinctBaseButtonProps

type ButtonVariantSecondaryProps = {
	variant?: 'secondary'
	icon: ImageResult | undefined
}

type ButtonVariantTertiaryProps = {
	variant?: 'tertiary'
	isBlur?: boolean
	icon?: ImageResult | IconName
}

export const Button: FunctionComponent<ButtonProps> = ({
	children,
	isFullWidth = false,
	size = 'large',
	...otherProps
}) => {
	otherProps.variant = otherProps.variant ?? 'primary'
	const icon = useMemo(() => {
		if (otherProps.variant !== 'secondary' && otherProps.variant !== 'tertiary') {
			return undefined
		}
		return otherProps.icon
	}, [otherProps])

	const isBlur = useMemo(() => {
		if (otherProps.variant === 'tertiary') {
			return otherProps.isBlur ?? false
		}
		return false
	}, [otherProps])
	return (
		<ButtonBase
			className={clsx(
				styles.wrapper,
				styles[`is_size_${size}`],
				styles[`is_variant_${otherProps.variant}`],
				isFullWidth && styles.is_fullWidth,
				isBlur && styles.is_blur,
			)}
			{...otherProps}
		>
			<Ripple />
			<div className={styles.main}>
				{icon && (
					<div className={styles.icon}>
						{typeof icon === 'string' ? (
							<Icon name={icon} />
						) : (
							<Image className={styles.iconIn} src={icon.url} alt={icon.alt ?? ''} fill sizes="30px" />
						)}
					</div>
				)}
				{children}
				{otherProps.variant === 'tertiary' && (
					<div className={styles.arrow}>
						<Icon name="arrow" />
					</div>
				)}
				{isBlur && <div className={styles.blur} />}
			</div>
		</ButtonBase>
	)
}
