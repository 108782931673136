import clsx from 'clsx'
import Image from 'next/image'
import type { FunctionComponent } from 'react'
import styles from './PersonIcon.module.sass'

const defaultPersons = {
	owner: 'owner',
	customer: 'customer',
	manager: 'manager',
	employee: 'employee',
} as const
type PersonIconTypeProps = (typeof defaultPersons)[keyof typeof defaultPersons]

export type PersonIconProps = {
	type: PersonIconTypeProps
}

export type PersonIconElement = {
	type: 'personIcon'
	suchThat: { person: PersonIconTypeProps }
}

export const PersonIcon: FunctionComponent<PersonIconProps> = ({ type }) => {
	return (
		<div className={clsx(styles.wrapper, styles[`is_${type}`])}>
			<Image className={styles.image} src={`/images/${type}.png`} fill sizes="40px" alt="" priority />
		</div>
	)
}
