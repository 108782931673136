import clsx from 'clsx'
import Image from 'next/image'
import { useCallback, useState, type FunctionComponent } from 'react'
import type { TestimonialListResult } from '../data/TestimonialListFragment'
import { BreakableContent } from './BreakableContent'
import { Container } from './Container'
import { Icon } from './Icon'
import styles from './Testimonials.module.sass'

export type TestimonialsProps = TestimonialListResult & {
	title?: string
}

export const Testimonials: FunctionComponent<TestimonialsProps> = ({ title, items }) => {
	const [itemsRef, setItemsRef] = useState<HTMLDivElement | null>(null)
	const [itemRef, setItemRef] = useState<HTMLDivElement | null>(null)

	const scroll = useCallback(
		(direction: 1 | -1) => {
			if (itemsRef === null || itemRef === null) {
				return
			}

			const cardWidth = parseInt(getComputedStyle(itemRef).width.replace('px', ''))

			const scrollSlideModifier = 1.1 // purpose: slide to the sides if it's almost at the beginning/end

			const scrollSlideOffset = direction * cardWidth * scrollSlideModifier

			itemsRef.scrollBy({
				left: scrollSlideOffset,
				behavior: 'smooth',
			})
		},
		[itemsRef, itemRef],
	)

	return (
		<div className={styles.wrapper}>
			{title && (
				<Container size="fullWidth">
					<div className={styles.header}>
						<h2 className={styles.title}>
							<BreakableContent breakableFrom="768" text={title} />
						</h2>
						<div className={styles.controls}>
							<button className={clsx(styles.button, styles.is_previous)} onClick={() => scroll(-1)}>
								<div className={styles.buttonIn}>
									<Icon name="arrow" />
								</div>
							</button>
							<button className={clsx(styles.button, styles.is_next)} onClick={() => scroll(1)}>
								<div className={styles.buttonIn}>
									<Icon name="arrow" />
								</div>
							</button>
						</div>
					</div>
				</Container>
			)}
			<div className={styles.items} ref={setItemsRef}>
				<div className={styles.empty} />
				{items.map(
					(item) =>
						item.testimonial && (
							<div key={item.id} className={styles.item} ref={setItemRef}>
								{item.testimonial.image && (
									<div className={styles.image}>
										<Image
											className={styles.imageIn}
											src={item.testimonial.image.url}
											alt={item.testimonial.image.alt ?? ''}
											fill
											sizes="media (min-width: 400px) 450px, 100vw"
										/>
									</div>
								)}
								{item.testimonial.logo && (
									<Image
										className={styles.logo}
										src={item.testimonial.logo.url}
										width={item.testimonial.logo.width}
										height={item.testimonial.logo.height}
										alt={item.testimonial.logo.alt ?? ''}
									/>
								)}
								<div className={styles.content}>
									<p className={styles.text}>{item.testimonial.text}</p>
									<div className={styles.footer}>
										{item.testimonial.label && <div className={styles.label}>{item.testimonial.label}</div>}
										{item.testimonial.name && <div className={styles.name}>{item.testimonial.name}</div>}
									</div>
								</div>
							</div>
						),
				)}
				<div className={styles.empty} />
			</div>
		</div>
	)
}
