import clsx from 'clsx'
import { useRouter } from 'next/router'
import { useEffect, useState, type FunctionComponent } from 'react'
import type { PageProps } from '../pages/[[...path]]'
import { Footer } from './Footer'
import { Header } from './Header'
import styles from './Layout.module.sass'

export type LayoutProps = NonNullable<Pick<PageProps, 'header' | 'footer'>> & {
	children: React.ReactNode
}

export const Layout: FunctionComponent<LayoutProps> = ({ header, children, footer }) => {
	const { asPath } = useRouter()
	const [isHomepage, setIsHomepage] = useState(false)

	useEffect(() => {
		// @TODO: remove "|| asPath === '/test'" after testing is finished
		if (asPath === '/') {
			setIsHomepage(true)
		} else {
			setIsHomepage(false)
		}
	}, [asPath])

	return (
		<div className={clsx(styles.wrapper, isHomepage && styles.is_homepage)}>
			{!isHomepage && (
				<div className={styles.headerWrapper}>
					<header className={styles.header}>
						{/* @TODO: gap top */}
						{/* <div className={styles.gapTop} /> */}
						{header && <Header {...header} />}
					</header>
				</div>
			)}
			<main className={styles.main}>{children}</main>
			{!isHomepage && <footer className={styles.footer}>{footer && <Footer {...footer} />}</footer>}
		</div>
	)
}
