import Image from 'next/image'
import { useMemo, type FunctionComponent } from 'react'
import type { FeatureItemResult } from '../data/FeatureItemFragment'
import type { FeatureListResult } from '../data/FeatureListFragment'
import { BreakableContent } from './BreakableContent'
import { Container } from './Container'
import { FeatureAccordion } from './FeatureAccordion'
import styles from './Features.module.sass'

export type FeaturesProps = {
	title?: string
	featureList: FeatureListResult
}

export const Features: FunctionComponent<FeaturesProps> = ({ featureList, title }) => {
	const splitFeatures = useMemo(() => {
		const result: Array<Array<FeatureItemResult>> = []
		let index = 0
		const size = 2

		while (index < featureList.items.length) {
			result.push(featureList.items.slice(index, index + size))
			index += size
		}

		return result
	}, [featureList.items])
	return (
		<div className={styles.wrapper}>
			<Container>
				{title && (
					<h2 className={styles.title}>
						<BreakableContent text={title} />
					</h2>
				)}
				<div className={styles.features}>
					{splitFeatures.map((features, index) => (
						<Row key={index} items={features} />
					))}
				</div>
			</Container>
		</div>
	)
}

type RowProps = {
	items: FeatureItemResult[]
}

const Row: FunctionComponent<RowProps> = ({ items }) => {
	if (items.length <= 0) {
		return null
	}

	return (
		<div className={styles.list}>
			{items.map((item) => {
				const { id, feature } = item
				if (!feature) {
					return <div key={id} className={styles.list} />
				}
				return (
					<div key={id} className={styles.item}>
						{feature.backgroundImage && (
							<Image
								className={styles.image}
								src={feature.backgroundImage.url}
								alt={feature.backgroundImage.alt ?? ''}
								fill
								// @TODO: sizes
								sizes="610px"
							/>
						)}
						{feature.label && (
							<FeatureAccordion
								label={feature.label}
								text={feature.text}
								textColorType={feature.textColorType}
								horizontalAlignmentType={feature.horizontalAlignmentType}
								verticalAlignmentType={feature.verticalAlignmentType}
							/>
						)}
					</div>
				)
			})}
		</div>
	)
}
