import clsx from 'clsx'
import Image from 'next/image'
import { useEffect, useRef, useState, type FunctionComponent } from 'react'
import { useIntersection } from 'react-use'
import type { TabRevealingTextListResult } from '../data/TabRevealingTextListFragment'
import { BreakableContent } from './BreakableContent'
import { Container } from './Container'
import { RichTextRendererWithElements } from './RichTextRendererWithElements'
import styles from './TabRevealingTextList.module.sass'

export type TabRevealingTextListProps = {
	title?: string
} & TabRevealingTextListResult

const TIMEOUT = 2500

export const TabRevealingTextList: FunctionComponent<TabRevealingTextListProps> = ({ title, items }) => {
	const [activeIndex, setActiveIndex] = useState<number>(0)
	const [isButtonClicked, setIsButtonClicked] = useState<boolean>(false)
	const itemsCount = items.length - 1
	const intersectionRef = useRef(null)
	const intersection = useIntersection(intersectionRef, {
		root: null,
		threshold: 0.8,
	})

	useEffect(() => {
		const updateActiveIndex = () => {
			if (!isButtonClicked && items && intersection?.isIntersecting) {
				setActiveIndex(activeIndex === itemsCount ? 0 : activeIndex + 1)
			}
		}

		const timeoutId = setTimeout(() => updateActiveIndex(), TIMEOUT)

		return () => {
			clearTimeout(timeoutId)
		}
	}, [activeIndex, intersection?.isIntersecting, isButtonClicked, items, itemsCount])

	return (
		<div className={styles.wrapper}>
			<Container>
				{title && (
					<h2 className={styles.title}>
						<div
							className={styles.shape}
							style={{
								'--TabRevealingTextList-shapeIn-width': 366,
							}}
						>
							<Image
								className={styles.shapeIn}
								src="/shapes/splashed-mayonaise-2.svg"
								width={366}
								height={215}
								alt=""
							/>
						</div>
						<BreakableContent text={title} />
					</h2>
				)}
				<div className={styles.main}>
					<div className={styles.tabs}>
						{items.map(
							(item, index) =>
								item.tabRevealingText && (
									<button
										key={item.id}
										className={clsx(styles.tab, activeIndex === index && styles.is_active)}
										onClick={() => {
											setActiveIndex(index)
											setIsButtonClicked(true)
										}}
									>
										{item.tabRevealingText.tabLabel}
									</button>
								),
						)}
					</div>
					<div className={styles.contents} ref={intersectionRef}>
						{items.map(
							(item, index) =>
								item.tabRevealingText && (
									<div key={item.id} className={clsx(styles.content, activeIndex === index && styles.is_active)}>
										<RichTextRendererWithElements source={item.tabRevealingText.text} />
									</div>
								),
						)}
					</div>
				</div>
			</Container>
		</div>
	)
}
