import clsx from 'clsx'
import Image from 'next/image'
import type { FunctionComponent } from 'react'
import type { MediumResult } from '../data/MediumFragment'
import { BreakableContent } from './BreakableContent'
import { Container } from './Container'
import styles from './Medium.module.sass'

export type MediumProps = {
	title?: string
	medium: MediumResult
}

export const Medium: FunctionComponent<MediumProps> = ({ title, medium }) => {
	return (
		<div className={styles.wrapper}>
			<Container>
				{title && (
					<h2 className={styles.title}>
						<BreakableContent text={title} />
					</h2>
				)}
				<div
					className={clsx(styles.medium, styles[`is_${medium.type}`])}
					style={{
						'--Medium-video-width': medium.video?.width,
						'--Medium-video-height': medium.video?.height,
					}}
				>
					{medium.type === 'image' && medium.image ? (
						<Image
							className={styles.image}
							src={medium.image.url}
							width={medium.image.width}
							height={medium.image.height}
							alt={medium.image.alt ?? ''}
						/>
					) : (
						medium.type === 'video' &&
						medium.video && (
							<video className={styles.video} controls playsInline poster={medium.video.poster?.url}>
								<source src={medium.video.src} type={medium.video.type} />
							</video>
						)
					)}
				</div>
			</Container>
		</div>
	)
}
