import clsx from 'clsx'
import Image from 'next/image'
import type { FunctionComponent } from 'react'
import type { ImageResult } from '../data/ImageFragment'
import { ContactForm, type ContactFormProps } from './ContactForm'
import { ContactLink } from './ContactLink'
import styles from './ContactWaitlist.module.sass'
import { Container } from './Container'
import { useContactSubmissionContactPersons } from './contexts/ContactSubmissionProvider'
import { useTranslate } from './contexts/TranslationsContextProvider'

export type ContactWaitlistProps = {
	image?: ImageResult
	title: string | undefined
	text: string | undefined
	formTitle?: ContactFormProps['title']
}

export const ContactWaitlist: FunctionComponent<ContactWaitlistProps> = ({ title, text, formTitle }) => {
	const contactPersons = useContactSubmissionContactPersons()
	const translation = useTranslate()

	return (
		<div className={styles.wrapper}>
			<Container>
				<div className={styles.main}>
					<div className={styles.content}>
						<div className={styles.contentRow}>
							<div className={styles.background}>
								<div className={styles.shape}>
									<Image
										className={clsx(styles.shapeIn, styles.is_desktop)}
										src="/images/splashed-mayonaise-3.png"
										width="497"
										height="247"
										alt=""
									/>
									<Image
										className={clsx(styles.shapeIn, styles.is_mobile)}
										src="/images/splashed-mayonaise-3-without-cap.png"
										width="386"
										height="247"
										alt=""
									/>
								</div>
							</div>
							{title && (
								<h1
									className={styles.title}
									dangerouslySetInnerHTML={{
										__html: title,
									}}
								/>
							)}
							{text && (
								<p
									className={styles.text}
									dangerouslySetInnerHTML={{
										__html: text,
									}}
								/>
							)}
						</div>
						{contactPersons.length > 0 && (
							<div className={styles.contactPersonsWrapper}>
								<ul className={styles.contactPersons}>
									{contactPersons.map(({ id, image, name, localesByLocale }) => (
										<li key={id} className={styles.contactPerson}>
											<div className={styles.contactColumn}>
												{image && (
													<div
														className={styles.contactImage}
														style={{
															'--ContactWaitlist-contactImage-width': image.width,
															'--ContactWaitlist-contactImage-height': image.height,
														}}
													>
														<Image src={image.url} alt={image.alt ?? ''} fill />
													</div>
												)}
												<div className={styles.contactContent}>
													<div className={styles.contactName}>{name}</div>
													<div className={styles.contactPosition}>{localesByLocale?.position}</div>
												</div>
											</div>
											<div className={styles.contactInfo}>
												{localesByLocale?.email && (
													<div className={styles.contactInfoRow}>
														<ContactLink
															type="email"
															link={localesByLocale.email}
															label={localesByLocale.email}
															buttonLabel={translation('contact.writeMe')}
														/>
													</div>
												)}
												{localesByLocale?.phone && (
													<div className={styles.contactInfoRow}>
														<ContactLink
															type="phone"
															link={localesByLocale.phone}
															label={localesByLocale.phone}
															buttonLabel={translation('contact.callMe')}
														/>
													</div>
												)}
											</div>
										</li>
									))}
								</ul>
							</div>
						)}
					</div>
					<div className={styles.form}>
						<ContactForm title={formTitle} />
					</div>
				</div>
			</Container>
		</div>
	)
}
