import type { ReferenceRendererProps } from '@contember/react-client'
import { RichTextRenderer } from '@contember/react-client'
import clsx from 'clsx'
import Link from 'next/link'
import { useMemo, type FunctionComponent, type ReactNode } from 'react'
import type { ContentReferenceType } from '../../generated/contember/zeus'
import type { ContentBlockResult } from '../data/ContentBlockFragment'
import type { ContentResult } from '../data/ContentFragment'
import { contemberLinkToHrefTargetRel } from '../utilities/contemberLinkToHref'
import { useContentRendererCopyPasteBugWorkaround } from '../utilities/useContentRendererCopyPasteBugWorkaround'
import { AsideMediaContent } from './AsideMediaContent'
import { ContactWaitlist } from './ContactWaitlist'
import { Container } from './Container'
import styles from './ContentRenderer.module.sass'
import { Embed } from './Embed'
import { Features } from './Features'
import { Linkables } from './Linkables'
import { LogoList } from './LogoList'
import { Medium } from './Medium'
import { PersonIcon, type PersonIconElement } from './PersonIcon'
import { TabRevealingTextList } from './TabRevealingTextList'
import { Testimonials } from './Testimonials'
import { Wysiwyg } from './Wysiwyg'

export interface ContentRendererProps {
	content: ContentResult
	containerDisableGutters?: boolean
}

type Block = ReferenceRendererProps<ContentBlockResult['references'][number]>

const standaloneTypes = ['reference']
const nestedTypes = ['listItem', 'anchor', 'tableCell', 'tableRow', 'scrollTarget', 'link']

const referenceRenderers: {
	[referenceType in ContentReferenceType]?: (block: Block) => ReactNode
} = {
	medium: function medium({ reference }) {
		return reference.medium && <Medium title={reference.primaryText} medium={reference.medium} />
	},
	linkables: function linkables({ reference }) {
		return (
			<Container>
				<Linkables title={reference.primaryText} items={reference.linkables} />
			</Container>
		)
	},
	link: function link({ reference }) {
		return (
			reference.link && (
				<Container>
					<Link {...contemberLinkToHrefTargetRel(reference.link)}>{reference.link.title}</Link>
				</Container>
			)
		)
	},
	embed: function embed({ reference }) {
		return (
			reference.embed && (
				<Container>
					<Embed {...reference.embed} />
				</Container>
			)
		)
	},
	contactWaitlist: function contactWaitlist({ reference }) {
		return (
			<ContactWaitlist
				image={reference.image}
				title={reference.primaryText}
				text={reference.secondaryText}
				formTitle={reference.tertiaryText}
			/>
		)
	},
	asideMediaContent: function asideMediaContent({ reference }) {
		return reference.asideMediaContent && <AsideMediaContent {...reference.asideMediaContent} />
	},
	logoList: function logoList({ reference }) {
		return reference.logoList && <LogoList items={reference.logoList.items} />
	},
	features: function features({ reference }) {
		return reference.featureList && <Features title={reference.primaryText} featureList={reference.featureList} />
	},
	testimonials: function testimonials({ reference }) {
		return (
			reference.testimonialList && (
				<Testimonials title={reference.primaryText} items={reference.testimonialList.items} />
			)
		)
	},
	tabsReavealingText: function tabsReavealingText({ reference }) {
		return (
			reference.tabRevealingTextList && (
				<TabRevealingTextList title={reference.primaryText} items={reference.tabRevealingTextList.items} />
			)
		)
	},
}

type CustomElements = PersonIconElement

export const ContentRenderer: FunctionComponent<ContentRendererProps> = ({
	content,
	containerDisableGutters = false,
}) => {
	const blocks = useContentRendererCopyPasteBugWorkaround(content.blocks)

	return useMemo(
		() => (
			<div className={styles.wrapper}>
				<RichTextRenderer
					blocks={blocks}
					sourceField="json"
					renderElement={(...args) => {
						const [params] = args
						const element = params.element as (typeof params)['element'] | CustomElements
						const { type } = element

						if (type === 'table') {
							return (
								<div className={clsx(styles.section, styles[`is_reference_${type}`])}>
									<Container>{params.fallback}</Container>
								</div>
							)
						}

						if (type === 'personIcon') {
							const elementPersonIcon = element as PersonIconElement

							const type = elementPersonIcon.suchThat.person

							return <PersonIcon type={type} />
						}

						if (nestedTypes.includes(type)) {
							const reference = params.reference as ContentBlockResult['references'][number] // @TODO: remove cast
							if (params.referenceType === 'link' && reference.link) {
								return <Link {...contemberLinkToHrefTargetRel(reference.link)}>{params.children}</Link>
							}
							return params.fallback
						}

						if (standaloneTypes.includes(type)) {
							return (
								<div
									className={clsx(
										styles.section,
										params.referenceType && styles[`is_reference_${params.referenceType}`],
									)}
								>
									{type !== 'reference' || !params.referenceType || params.referenceType in referenceRenderers ? (
										params.fallback
									) : (
										<Container disableGutters={containerDisableGutters}>
											<div className={styles.notImplemented}>
												<div className={styles.notImplemented_name}>{params.referenceType}</div>
												is not yet implemented
											</div>
										</Container>
									)}
								</div>
							)
						}
						return (
							<div className={clsx(styles.section, styles.is_wysiwyg)}>
								<Container disableGutters={containerDisableGutters}>
									<Wysiwyg>{params.fallback}</Wysiwyg>
								</Container>
							</div>
						)
					}}
					referenceRenderers={referenceRenderers}
				/>
			</div>
		),
		[blocks, containerDisableGutters],
	)
}
