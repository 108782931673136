import Image from 'next/image'
import Link from 'next/link'
import type { FunctionComponent } from 'react'
import type { PageProps } from '../pages/[[...path]]'
import { Container } from './Container'
import styles from './Header.module.sass'

export type HeaderProps = NonNullable<PageProps['header']>

export const Header: FunctionComponent<HeaderProps> = ({
	// @TODO: will be maybe used in the future
	// localesByLocale,
	logo,
}) => {
	// @TODO: will be maybe used in the future
	// const contactButtonLink = localesByLocale?.contactButtonLink
	// const contactIcon = localesByLocale?.contactIcon
	return (
		<div className={styles.wrapper}>
			<Container>
				<div className={styles.in}>
					{logo?.url && (
						<Link href="/">
							<div
								className={styles.logo}
								style={{
									'--Header-logo-width': logo?.width,
									'--Header-logo-height': logo?.height,
								}}
							>
								<Image src={logo.url} alt={logo?.alt ?? ''} fill />
							</div>
						</Link>
					)}
					{/* @TODO: will be maybe used in the future */}
					{/* <div className={styles.column}>
						{contactButtonLink && (
							<Button
								type="link"
								variant="secondary"
								icon={contactIcon}
								{...contemberLinkToHrefTargetRel(contactButtonLink)}
							>
								{contactButtonLink.title}
							</Button>
						)}
					</div> */}
				</div>
			</Container>
		</div>
	)
}
