import clsx from 'clsx'
import { Fragment, type FunctionComponent } from 'react'
import styles from './BreakableContent.module.sass'

export type BreakableContentProps = {
	breakableFrom?: 'always' | '480' | '768' | '992'
	text: string
	isParagraph?: boolean
}

export const BreakableContent: FunctionComponent<BreakableContentProps> = ({
	text,
	breakableFrom = 'always',
	isParagraph = false,
}) => {
	const classNames = clsx(
		styles.wrapper,
		breakableFrom && styles[`is_breakableFrom_${breakableFrom}`],
		isParagraph && styles.is_paragraph,
	)

	if (isParagraph) {
		return (
			<p className={classNames}>
				<BreakableContentChildren text={text} />
			</p>
		)
	}

	return (
		<div className={classNames}>
			<BreakableContentChildren text={text} />
		</div>
	)
}

export const BreakableContentChildren: FunctionComponent<Pick<BreakableContentProps, 'text'>> = ({ text }) => {
	const lines = text.split('\n')
	return (
		<>
			{lines.map((line, i) => (
				<Fragment key={i}>
					{i != 0 && <br />}
					{line}
				</Fragment>
			))}
		</>
	)
}
