import Image from 'next/image'
import type { FunctionComponent } from 'react'
import type { PageProps } from '../pages/[[...path]]'
import { contemberLinkToHrefTargetRel } from '../utilities/contemberLinkToHref'
import { Button } from './Button'
import { HeroShape } from './HeroShape'
import styles from './HomePage.module.sass'

export type HomePageProps = NonNullable<PageProps['page']['homePage']> & Pick<PageProps, 'currency'>

export const HomePage: FunctionComponent<HomePageProps> = ({
	heroButtonLink,
	// heroTitle,
	// heroText,
	// heroTotalPriceText,
	// content,
	// heroWindowCards,
	// currency,
}) => {
	// if (!content) {
	// 	return null
	// }
	// Note: for now we are only displaying the logo and the hero button leading to the contact page
	return (
		<main className={styles.main}>
			<div className={styles.background}>
				<div className={styles.logo}>
					<Image className={styles.logoIn} src="/logo.svg" alt="KiloMayo logo" fill />
				</div>
				<div className={styles.shape}>
					<HeroShape displayWhen="isIntersecting" animationSpeed="slow" />
				</div>
			</div>
			{heroButtonLink && (
				<div className={styles.button}>
					<Button
						type="link"
						variant="tertiary"
						isBlur
						{...contemberLinkToHrefTargetRel(heroButtonLink)}
						icon="kilomayo"
					>
						{heroButtonLink?.title}
					</Button>
				</div>
			)}
		</main>
	)
	// @TODO: Implement the following code when the page is ready
	// return (
	// 	<div className={styles.wrapper}>
	// 		{heroTitle && (
	// 			<div className={styles.hero}>
	// 				<HeroAnimation
	// 					buttonLink={heroButtonLink}
	// 					title={heroTitle}
	// 					text={heroText}
	// 					cards={heroWindowCards}
	// 					currency={currency}
	// 					totalPriceText={heroTotalPriceText}
	// 				/>
	// 			</div>
	// 		)}
	// 		<ContentRenderer content={content} />
	// 	</div>
	// )
}
