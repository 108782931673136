import React, { useMemo } from 'react'
import type { ContactSubmissionContactPersonResult } from '../../data/ContactSumissionContactPersonFragment'
import type { PageProps } from '../../pages/[[...path]]'

type ContactSubmissionData = {
	contactPersons: ContactSubmissionContactPersonResult[]
}

const defaultValue: ContactSubmissionData = {
	contactPersons: [],
}

export const ContactSubmissionContext = React.createContext(defaultValue)
export const ContactSubmissionContextProvider: React.FunctionComponent<{
	data: PageProps['contactSubbmisionData']
	children?: React.ReactNode
}> = ({ children, data: { contactPersons } }) => {
	const values = useMemo<ContactSubmissionData>(() => {
		return {
			contactPersons: contactPersons,
		}
	}, [contactPersons])
	return <ContactSubmissionContext.Provider value={values}>{children}</ContactSubmissionContext.Provider>
}

export const useContactSubmissionContactPersons = () => {
	const context = React.useContext(ContactSubmissionContext)
	return context.contactPersons
}
