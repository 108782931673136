import clsx from 'clsx'
import type { FunctionComponent } from 'react'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { useMirrorLoading } from 'shared-loading-indicator'
import { api } from '../utilities/api'
import { BreakableContent } from './BreakableContent'
import { Button } from './Button'
import { Checkbox } from './Checkbox'
import styles from './ContactForm.module.sass'
import { InputWithLabel, TextAreaWithLabel } from './Inputs'
import { useTranslate } from './contexts/TranslationsContextProvider'

export type ContactFormProps = {
	title?: string
}

export const ContactForm: FunctionComponent<ContactFormProps> = ({ title }) => {
	const translation = useTranslate()
	// form
	const [name, setName] = useState<string | undefined>('')
	const [businessName, setBusinessName] = useState('')
	const [email, setEmail] = useState<string | undefined>('')
	const [phone, setPhone] = useState('')
	const [note, setNote] = useState('')
	const [isASAPMeetingDemanded, setIsASAPMeetingDemanded] = useState(false)
	const [isPrivacyPolicy, setIsPrivacyPolicy] = useState(false)

	const mutation = api.contact.send.useMutation({
		onError: () => {
			toast(translation('contactForm.error'), {
				type: 'error',
			})
		},
	})
	const isPending = mutation.isPending
	useMirrorLoading(isPending)

	// success/error form handling
	useEffect(() => {
		if (mutation.isSuccess) {
			if (mutation.data?.mailStatus) {
				toast(translation('contactForm.success'), {
					type: 'success',
				})
				setName('')
				setBusinessName('')
				setEmail('')
				setPhone('')
				setNote('')
				setIsASAPMeetingDemanded(false)
				setIsPrivacyPolicy(false)
			} else {
				toast(translation('contactForm.error'), {
					type: 'error',
				})
				console.error('Contember error: ', mutation.data?.contemberStatus.errorMessage)
				console.error('Mail status', mutation.data?.mailStatus)
			}
			mutation.reset()
		} else if (mutation.isError) {
			toast(translation('contactForm.error'), {
				type: 'error',
			})
			console.error(mutation.error)
			mutation.reset()
		}
		// Include the dependencies related to mutation and translation
	}, [translation, mutation])

	return (
		<form
			className={styles.wrapper}
			onSubmit={(event) => {
				event.preventDefault()
				if (!(name && email)) {
					console.warn('Name and email are required')
					return
				}
				mutation.mutate({
					name,
					businessName,
					email,
					phone,
					note,
					isASAPMeetingDemanded,
					isPrivacyPolicy,
				})
			}}
		>
			{title && (
				<h2 className={styles.title}>
					<BreakableContent text={title} />
				</h2>
			)}
			<div className={styles.row}>
				<InputWithLabel
					styleType="large"
					label={translation('contactForm.name.label')}
					name="name"
					type="text"
					autoComplete="name"
					value={name}
					onChange={(event) => {
						setName(event.target.value)
					}}
					required
				/>
			</div>
			<div className={styles.row}>
				<InputWithLabel
					styleType="large"
					label={translation('contactForm.businessName.label')}
					name="businessName"
					type="text"
					autoComplete="organization"
					value={businessName}
					onChange={(event) => {
						setBusinessName(event.target.value)
					}}
				/>
			</div>
			<div className={styles.row}>
				<div className={styles.columns}>
					<div className={styles.column}>
						<InputWithLabel
							styleType="small"
							label={translation('contactForm.email.label')}
							name="email"
							type="email"
							autoComplete="email"
							value={email}
							onChange={(event) => {
								setEmail(event.target.value)
							}}
							required
						/>
						<div className={styles.description}>{translation('contactForm.email.description')}</div>
					</div>
					<div className={styles.column}>
						<InputWithLabel
							styleType="small"
							label={translation('contactForm.phone.label')}
							name="phone"
							type="tel"
							autoComplete="tel"
							value={phone}
							onChange={(event) => {
								setPhone(event.target.value)
							}}
						/>
						<div className={styles.description}>{translation('contactForm.phone.description')}</div>
					</div>
				</div>
			</div>
			<div className={styles.row}>
				<TextAreaWithLabel
					styleType="small"
					label={translation('contactForm.note.label')}
					name="note"
					value={note}
					onChange={(event) => {
						setNote(event.target.value)
					}}
				/>
			</div>
			<div className={styles.row}>
				<div className={styles.checkbox}>
					<Checkbox
						label={translation('contactForm.asapMeetingDemanded')}
						checked={isASAPMeetingDemanded}
						onChange={(event) => {
							setIsASAPMeetingDemanded(event.target.checked)
						}}
					/>
				</div>
				<div className={styles.checkbox}>
					<Checkbox
						required
						label={translation('contactForm.agreeProcessingPersonalData')}
						checked={isPrivacyPolicy}
						onChange={(event) => {
							setIsPrivacyPolicy(event.target.checked)
						}}
					/>
				</div>
				<div
					className={clsx(styles.description, styles.is_checkbox)}
					dangerouslySetInnerHTML={{
						__html: translation('contactForm.privacyPolicy'),
					}}
				/>
			</div>
			<div className={styles.submit}>
				<Button type="submit" size="large" variant="form" disabled={isPending}>
					{translation('contactForm.submit')}
				</Button>
			</div>
		</form>
	)
}
