import clsx from 'clsx'
import type { FunctionComponent, InputHTMLAttributes, TextareaHTMLAttributes } from 'react'
import styles from './Inputs.module.sass'
import { useTranslate } from './contexts/TranslationsContextProvider'

type StyleType = {
	styleType: 'large' | 'small'
}

export type InputProps = Pick<
	InputHTMLAttributes<HTMLInputElement>,
	'name' | 'type' | 'required' | 'value' | 'onChange' | 'onFocus' | 'onBlur' | 'autoComplete' | 'placeholder'
> &
	StyleType

export const Input: FunctionComponent<InputProps> = ({ styleType, ...props }) => {
	return <input className={clsx(styles.input, styles[`is_${styleType}`])} {...props} />
}

export type TextAreaProps = Pick<
	TextareaHTMLAttributes<HTMLTextAreaElement>,
	'name' | 'required' | 'value' | 'onChange' | 'onFocus' | 'onBlur' | 'placeholder'
> &
	StyleType

export const TextArea: FunctionComponent<TextAreaProps> = ({ styleType, ...props }) => {
	return <textarea className={clsx(styles.input, styles[`is_${styleType}`], styles.is_textarea)} {...props} />
}

export type InputWithLabelProps = InputProps & {
	label: string
}

export const InputWithLabel: FunctionComponent<InputWithLabelProps> = ({ label, ...inputProps }) => {
	const translate = useTranslate()
	return (
		<label className={styles.label}>
			<div className={styles.labelText}>
				{label}
				{inputProps.required && <span className={styles.required}>{` ${translate('input.required')}`}</span>}
			</div>
			<div className={styles.labelInput}>
				<Input {...inputProps} />
				{inputProps.styleType === 'small' && <div className={styles.is_activeBorder} />}
			</div>
		</label>
	)
}

export type TextAreaWithLabelProps = TextAreaProps & {
	label: string
}

export const TextAreaWithLabel: FunctionComponent<TextAreaWithLabelProps> = ({ label, ...textAreaProps }) => {
	const translate = useTranslate()
	return (
		<label className={styles.label}>
			<div className={styles.labelText}>
				{label}
				{textAreaProps.required && <span className={styles.required}>{` (${translate('required')})`}</span>}
			</div>
			<div className={styles.labelTextArea}>
				<TextArea {...textAreaProps} />
				{textAreaProps.styleType === 'small' && <div className={styles.is_activeBorder} />}
			</div>
		</label>
	)
}
